import React from 'react';
import PropTypes from "prop-types";
import {Trans} from "gatsby-plugin-react-i18next";
import callIcon from "../../images/shared/call-icon.svg";
import emailIcon from "../../images/shared/email-icon.svg";
import chatIcon from "../../images/shared/chat-icon.svg";

const ContactCardsTwo = (props) => {
  return (
      <>
        <div
            className={'contact-card-container show'}>
          <div className={'contact-card'}>
            <img src={callIcon} alt="email icon" width={'100'} height={'100'}/>
            <div>
              <h4><Trans>call</Trans></h4>
              <p><a href={"tel:" + props.info.phone}>{props.info.phone}</a></p>
            </div>
            <a href={"tel:" + props.info.phone} className="btn btn--orange">
              <Trans>contact_us</Trans>
            </a>
          </div>
          <div className={'contact-card'}>
            <img src={emailIcon} alt="email icon" width={'100'} height={'100'}/>
            <div>
              <h4><Trans>email</Trans></h4>
              <p><Trans>email_product_expert</Trans></p>
            </div>
            <a href="/contact/email-product-expert/" className="btn btn--orange">
              <Trans>contact_us</Trans>
            </a>
          </div>
          <div className={'contact-card'}>
            <img src={chatIcon} alt="chat icon" width={'100'} height={'100'}/>
            <div>
              <h4><Trans>chat</Trans></h4>
              <p><Trans>chat_with_product_expert</Trans></p>
            </div>
            <a rel={'nofollow'} onClick={props.handleChatClick} href="javascript: null;" className="btn btn--orange">
              <Trans>contact_us</Trans>
            </a>
          </div>
        </div>

      </>
  )

}

ContactCardsTwo.propTypes = {
  info: PropTypes.object,
  handleChatClick: PropTypes.func
};

export default ContactCardsTwo;