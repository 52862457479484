import React, {useState, useEffect} from "react";
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';
import ContactCardsTwo from '../../components/contact/contact-cards-step2';
import {StaticImage} from "gatsby-plugin-image";
import Settings from "../../constants/company/settings";

const IndexPage = () => {
  const {t} = useTranslation();
  const [infoCurrent, setInfoCurrent] = useState({
    header: t('digilock_americas'),
    address: t('digilock_americas_address'),
    phone: Settings.PHONE_SALES_AMERICAS,
    emailSales: t('email_sales'),
    email: Settings.SALES_EMAIL,
    liveChat: t('live_chat'),
    liveChatCopy: t('live_chat_copy'),
    tollFree: t('toll_free'),
    tollFreeNumber: Settings.PHONE_SALES_TOLL_FREE_AMERICAS,
    phoneNumber: Settings.PHONE_SALES_TOLL_FREE_AMERICAS
  });

  function handleSalesChatClick() {
    window.history.pushState({}, 'bot', '?bot=sales');
    window.HubSpotConversations.clear({resetWidget: true});
  }
  return (
      <Layout>
        <Seo
            title={t('contact_title')}
            description={t('des_contact')}
        />
        <div className="contact">
          <section className="hero">
            <div className="container">
              <h1 dangerouslySetInnerHTML={{__html:t('contact_us')}} />
              <p><Trans>select_preferred_method</Trans></p>
            </div>
          </section>
          <section className="contact-info">
            <div className="container">
              <div className="sales-form">
                <ContactCardsTwo
                    info={infoCurrent}
                    handleChatClick={handleSalesChatClick}
                />
              </div>
            </div>
          </section>
          <section className="global-offices">
            <div className="container offices">
              <h2>
                <Trans>global_offices</Trans>
              </h2>
              <div>
                <Link to={'/about/offices/#gotoAmericas'} role={'button'}>
                  <figure>
                    <StaticImage
                        src="../../images/about/digilock-americas.png"
                        width={342}
                        height={236}
                        formats={["auto", "webp", "avif"]}
                        alt="Digilock Americas, San Francisco"
                    />
                    <figcaption className={'center'}>
                      <Trans>Digilock Americas</Trans>
                    </figcaption>
                  </figure>
                </Link>
                <Link to={'/about/offices/#gotoEurope'} role={'button'}>
                  <figure>
                    <StaticImage
                        src="../../images/about/digilock-europe-netherlands.png"
                        width={342}
                        height={236}
                        formats={["auto", "webp", "avif"]}
                        alt="Digilock Netherlands"
                    />
                    <figcaption className={'center'}>
                      <Trans>Digilock Europe, The Netherlands</Trans>
                    </figcaption>
                  </figure>
                </Link>
                <Link to={'/about/offices/#gotoAsia'} role={'button'}>
                  <figure>
                    <StaticImage
                        src="../../images/about/digilock-asia.png"
                        width={342}
                        height={236}
                        formats={["auto", "webp", "avif"]}
                        alt="Digilock Asia"
                    />
                    <figcaption className={'center'}>
                      <Trans>Digilock Asia, Hong Kong</Trans>
                    </figcaption>
                  </figure>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
